<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col>
          <el-button type="primary" size="small" @click="showAddMenuDialog">添加菜单</el-button>
        </el-col>
      </el-row>
      <!-- table表格区域 -->
      <el-table :data="menulist" border stripe v-loading="loading">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="菜单名称" prop="menuName" width="180px"></el-table-column>
        <el-table-column align="center" label="地址" prop="menuUrl"></el-table-column>
        <el-table-column align="center" label="菜单图标" prop="menuIcon">
          <template slot-scope="scope">
            <!-- 图标 -->
            <i :class="scope.row.menuIcon"></i>
          </template>
        </el-table-column>
        <el-table-column align="center" label="菜单类型" prop="type">
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.type === 'M' ? 'success' : 'danger'"
                disable-transitions
            >{{ scope.row.type === 'M' ? '菜单' : '按钮' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="权限值" prop="authorizeValue"></el-table-column>
        <el-table-column align="center" label="上级菜单ID" prop="menuId"></el-table-column>
        <el-table-column align="center" label="顺序" prop="number"></el-table-column>
        <el-table-column prop="status" align="center" label="状态" width="100">
          <template slot-scope="scope">
            <el-tag
                :type="scope.row.status === 0 ? 'success' : 'danger'"
                disable-transitions
            >{{ scope.row.status === 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="260px">
          <template slot-scope="scope">
            <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="hadleUpdate(scope.row)"
            >编辑
            </el-button>
            <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="hanldDelMenu(scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.current"
          :page-sizes="[5, 10, 15]"
          :page-size="querInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      ></el-pagination>
      <!-- 添加菜单对话框 -->
      <el-dialog title="添加菜单" :visible.sync="AddMenuDialog" width="30%" :before-close="closeAdd">
        <!-- 内容主题区域 -->
        <el-form :model="addMenuForm" :rules="rules" ref="addMenuFormRef" class="demo-ruleForm">
          <el-form-item label="菜单名称:" prop="menuName" label-width="100px">
            <el-input v-model="addMenuForm.menuName"></el-input>
          </el-form-item>
          <el-form-item label="路由地址:" prop="menuUrl" label-width="100px">
            <el-input v-model="addMenuForm.menuUrl"></el-input>
          </el-form-item>
          <el-form-item label="菜单图标:" prop="menuIcon" label-width="100px">
            <el-input v-model="addMenuForm.menuIcon"></el-input>
          </el-form-item>
          <el-form-item label="菜单类型:" prop="type" label-width="100px">
            <el-radio-group v-model="addMenuForm.type">
              <el-radio label="M">菜单</el-radio>
              <el-radio label="B">按钮</el-radio>
              <!-- <el-radio :label="9">备选项</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="权限值:" prop="authorizeValue" label-width="100px">
            <el-input v-model="addMenuForm.authorizeValue"></el-input>
          </el-form-item>
          <el-form-item label="上级菜单ID:" prop="menuId" label-width="100px">
            <el-input v-model="addMenuForm.menuId"></el-input>
          </el-form-item>
          <el-form-item label="顺序(倒序):" prop="number" label-width="100px">
            <el-input v-model="addMenuForm.number"></el-input>
          </el-form-item>
          <el-form-item label="状态:" label-width="100px">
            <el-switch v-model="addStatus" active-text="启用" inactive-text="停用"></el-switch>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeAdd">取 消</el-button>
          <el-button type="primary" @click="handleAddMenus(addMenuForm)">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑菜单 -->
      <el-dialog
          title="编辑菜单"
          :visible.sync="UpdataMenuDialog"
          width="30%"
          :before-close="updataMenuDialogVisible"
      >
        <!-- 内容主题区域 -->
        <el-form :model="updataMenuForm" :rules="rules" ref="updataMenuFormRef" class="demo-ruleForm">
          <el-form-item label="菜单名称:" prop="menuName" label-width="100px">
            <el-input v-model="updataMenuForm.menuName"></el-input>
          </el-form-item>
          <el-form-item label="路由地址:" prop="menuUrl" label-width="100px">
            <el-input v-model="updataMenuForm.menuUrl"></el-input>
          </el-form-item>
          <el-form-item label="菜单图标:" prop="menuIcon" label-width="100px">
            <el-input v-model="updataMenuForm.menuIcon"></el-input>
          </el-form-item>
          <el-form-item label="菜单类型:" prop="type" label-width="100px">
            <el-radio-group v-model="updataMenuForm.type">
              <el-radio label="M">菜单</el-radio>
              <el-radio label="B">按钮</el-radio>
              <!-- <el-radio :label="9">备选项</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="权限值:" prop="authorizeValue" label-width="100px">
            <el-input v-model="updataMenuForm.authorizeValue"></el-input>
          </el-form-item>
          <el-form-item label="上级菜单ID:" prop="menuId" label-width="100px">
            <el-input v-model="updataMenuForm.menuId"></el-input>
          </el-form-item>
          <el-form-item label="顺序(倒序):" prop="number" label-width="100px">
            <el-input v-model="updataMenuForm.number"></el-input>
          </el-form-item>
          <el-form-item label="状态:" label-width="100px">
            <el-switch v-model="updataMenuForm.status" active-text="启用" inactive-text="停用" :active-value="0"
                       :inactive-value="1"></el-switch>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="updataMenuDialogVisible()">取 消</el-button>
          <el-button type="primary" @click="handleDataMenus(addMenuForm)">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import {MenuList, AddMenu, DelMenu, setMenu} from '@/apis/system.js'

export default {
  data() {
    return {
      querInfo: {
        // type: 3,
        current: 1,
        size: 10
      },

      // 商品分类的数据列表，默认为空
      menulist: [],
      // 总数据条数
      total: 0,
      id: '',

      // 控制编辑菜单对话框的显示与隐藏
      UpdataMenuDialog: false,
      // 添加分类的表单数据对象
      addMenuDialogVisible() {
        this.AddMenuDialog = false
      },

      addMenuForm: {
        menuName: '',
        menuUrl: '',
        menuIcon: '',
        type: 'M',
        authorizeValue: '',
        menuId: '',
        number: '',
        status: '',
        id: ''
      },

      updataMenuForm: {
        menuName: '',
        menuUrl: '',
        menuIcon: '',
        type: 'M',
        authorizeValue: '',
        menuId: '',
        number: '',
        status: '',
        id: ''
      },
      rules: {
        menuName: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
        ],
        menuType: [{required: true, message: '请选择菜单类型', trigger: 'blur'}],
        menuId: [{required: true, message: '请输入上级菜单id', trigger: 'blur'}],
        number: [{required: true, message: '请输入顺序', trigger: 'blur'}]
      },

      AddMenuDialog: false,
      // 添加菜单状态
      addStatus: true,
      // 编辑菜单状态
      loading: false,
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    getMenuList() {
      this.loading = true
      MenuList(this.querInfo).then(data => {
        this.menulist = data.data.records
        this.total = data.data.total
        this.querInfo.size = data.data.size
        this.querInfo.current = data.data.current
        this.id = data.data.id
      })
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    // 监听 pagesize 改变

    handleSizeChange(newSize) {
      this.querInfo.size = newSize
      this.getMenuList()
    },
    // 监听 pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.current = newPage
      this.getMenuList()
    },
    // 监听对话框的关闭事件，重置表单数据
    closeAdd() {
      this.AddMenuDialog = false
      this.addStatus = true
      this.$refs.addMenuFormRef.resetFields()
    },
    // 点击按钮，展示添加菜单的对话框
    showAddMenuDialog() {
      this.AddMenuDialog = true
    },
    // 添加菜单
    handleAddMenus(addMenuForm) {
      this.$refs.addMenuFormRef.validate(valid => {
        if (valid) {
          if (this.addStatus) {
            this.addMenuForm.status = 0
          } else {
            this.addMenuForm.status = 1
          }
          AddMenu(this.addMenuForm).then(data => {
            this.$message({
              type: 'success',
              message: data.msg
            })
            this.closeAdd()
            this.getMenuList()
          })
        } else {
          this.$message.error('请输入正确的表单格式')
        }
      })
    },
    // 点击按钮，展示编辑菜单的对话框
    hadleUpdate(scope) {
      this.UpdataMenuDialog = true
      this.updataMenuForm = scope

      if (scope.status === 0) {
        this.updataStatu = true
      } else {
        this.updataStatu = false
      }
    },
    // 编辑菜单
    handleDataMenus() {

      setMenu(this.updataMenuForm).then(data => {
        if (data.code == 200) {
          this.updataMenuDialogVisible()
          this.$message({
            type: 'success',
            message: data.msg
          })
        }
        this.getMenuList()
      })
    },

    updataMenuDialogVisible() {
      this.UpdataMenuDialog = false
      this.getMenuList()
    },
    // 删除菜单
    async hanldDelMenu(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('取消了删除！')
      }
      let data = {id: id}
      DelMenu(data).then(params => {
        this.$message({
          type: 'success',
          message: params.msg
        })
        this.getMenuList()
      })
    }
  }
}
</script>
<style lang="less" scoped></style>
