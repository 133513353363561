import axios from 'axios'
import QS from 'qs'

// 获取登录状态
export const loginStatus = params => {
    return axios.get(`/api/loginStatus`).then(res => res.data)
}

// 获取菜单列表
export const MenuList = params => {
    return axios.post(`/api/menu/menuPage`, QS.stringify(params)).then(res => res.data)
}
// 添加菜单
export const AddMenu = params => {
    return axios.post(`/api/menu/addMenu `, QS.stringify(params)).then(res => res.data)
}
// 删除菜单
export const DelMenu = params => {
    return axios.post(`/api/menu/delMenu`, QS.stringify(params)).then(res => res.data)
}
// 编辑菜单
export const setMenu = params => {
    return axios.post(`/api/menu/setMenu`, QS.stringify(params)).then(res => res.data)
}

// 角色列表
export const GetRolePage = params => {
    return axios.post(`/api/role/getRolePage`, QS.stringify(params)).then(res => res.data)
}
// 添加角色列表
export const AddRole = params => {
    return axios.post(`/api/role/addRole `, QS.stringify(params)).then(res => res.data)
}
// 删除角色
export const DelRole = params => {
    return axios.post(`/api/role/delRole`, QS.stringify(params)).then(res => res.data)
}
// 编辑角色
export const SetRole = params => {
    return axios.post(`/api/role/setRole`, QS.stringify(params)).then(res => res.data)
}
// 角色菜单集合
export const GetRoleMenu = params => {
    return axios.post(`/api/role/getRoleMenu`).then(res => res.data)
}

// 用户列表
export const GetUserPage = params => {
    return axios.post(`/api/users/usersPage`, QS.stringify(params)).then(res => res.data)
}
// 添加用户列表
export const AddUser = params => {
    return axios.post(`/api/users/addUsers`, QS.stringify(params)).then(res => res.data)
}
// 编辑用户
export const SetUser = params => {
    return axios.post(`/api/users/setUsers`, QS.stringify(params)).then(res => res.data)
}
// 删除用户
export const DelUser = params => {
    return axios.post(`/api/users/delUsers`, QS.stringify(params)).then(res => res.data)
}
// 新增用户角色列表
export const GetRoleList = params => {
    return axios.post(`/api/role/getRoleList`).then(res => res.data)
}

// 根据角色id获取名称
export const GetRoleID = params => {
    return axios.post(`/api/role/getRoleID`, QS.stringify(params)).then(res => res.data)
}